export const processMixin = {
    data() {
        return {
            updateRedirect: '/process?dataImportUpdated=true',
        }
    },
    computed: {
        redirectUrl() {
            if (this.isDetails || this.details) {
                return this.updateRedirect
            }
            if (this.$route.query.reportId) {
                return `/reports/dataproviders/preview/${this.$route.query.reportId}?continueFlow=true`
            }
            return '/process?dataImportCreated=true'
        },
    },
}
