<template>
    <div>
        <b-form-group label="Google Cloud Project" label-for="biguqery-projects" :label-cols="2" :horizontal="true">
            <b-row class="refresh-projects-row">
                <b-col md="8">
                    <wit-select
                        id="select-bigquery-project"
                        :options="[...projects, 'Custom projectId']"
                        v-model="$v.bigQueryForm.project.$model"
                        @input="
                            onProjectChange()
                            isCustomProjectId ? undefined : fetchDatasets()
                            onChange()
                        "
                        placeholder="Select project"
                        :disabled="disable"
                        :allow-empty="false"
                    ></wit-select>
                    <Feedback
                        v-if="!isCustomProjectId"
                        :state="validateRef('bigQueryForm.project')"
                        invalid="This field is required"
                        valid="Project is valid"
                    ></Feedback>
                </b-col>
                <b-col v-if="!disable" class="refresh-projects-col">
                    <b-button
                        @click="fetchProjects"
                        variant="warning"
                        v-b-tooltip.hover
                        title="If a project does not show up even though you have granted access, 
                        please wait a few seconds and refresh again. It takes a moment for the data to synchronize fully.
                        If you have granted access to a table or a dataset only, and not the entire project, 
                        it may not show up and you will need to enter the projectId by selecting 'Custom projectId' option."
                    >
                        <i class="icon-refresh"></i> Refresh
                    </b-button>
                </b-col>
            </b-row>
        </b-form-group>

        <b-form-group :label-cols="2" :horizontal="true" v-if="isCustomProjectId">
            <b-row class="refresh-projects-row">
                <b-col md="8">
                    <b-form-input
                        v-model="$v.bigQueryForm.customProjectId.$model"
                        placeholder="Your Custom projectId"
                        @input="onProjectChange()"
                        :disabled="disable"
                    />
                    <Feedback
                        :state="validateRef('bigQueryForm.project')"
                        invalid="This field is required"
                        valid="Project is valid"
                    ></Feedback>
                </b-col>
                <b-col v-if="!disable" class="refresh-projects-col">
                    <b-button
                        @click="
                            onChange()
                            fetchDatasets()
                        "
                        variant="warning"
                        v-b-tooltip.hover
                        title="Press this button when your Custom projectId is filled in to fetch available datasets.
                        If you have granted access to a table only, and not the entire project or a dataset, 
                        it may not show up and you will need to enter the datasetId by selecting 'Custom datasetId' option."
                    >
                        <i class="icon-refresh"></i> Fetch Datasets
                    </b-button>
                </b-col>
            </b-row>
        </b-form-group>

        <b-form-group
            v-if="['dataset', 'table'].includes(depth)"
            label="BigQuery Dataset"
            label-for="dataset"
            :label-cols="2"
            :horizontal="true"
        >
            <b-row>
                <b-col md="8">
                    <wit-select
                        id="select-bigquery-dataset"
                        @input="
                            onDatasetChange()
                            fetchTables()
                            onChange()
                        "
                        :placeholder="
                            datasetsFetched
                                ? 'Select BigQuery dataset'
                                : 'Select BigQuery project first to fetch datasets'
                        "
                        v-model="$v.bigQueryForm.dataset.$model"
                        :options="datasetFilter ? datasets.filter(table => table.includes(datasetFilter)) : datasets"
                        :disabled="!datasetsFetched || disable"
                        :allow-empty="false"
                    ></wit-select>
                    <Feedback
                        :state="validateRef('bigQueryForm.dataset')"
                        invalid="This field is required"
                        valid="Dataset is valid"
                    ></Feedback>
                </b-col>
            </b-row>
        </b-form-group>

        <b-form-group
            label="BigQuery Table"
            label-for="bigquery-table"
            v-if="depth === 'table'"
            :label-cols="2"
            :horizontal="true"
        >
            <b-row>
                <b-col md="8">
                    <wit-select
                        id="select-bigquery-table"
                        :placeholder="
                            tablesFetched ? 'Select BigQuery table' : 'Select BigQuery dataset first to fetch tables'
                        "
                        v-model="$v.bigQueryForm.table.$model"
                        :options="tableFilter ? tables.filter(table => table.includes(tableFilter)) : tables"
                        @input="onChange"
                        :disabled="!tablesFetched || !bigQueryForm.dataset || disable"
                        :allow-empty="false"
                    ></wit-select>
                    <Feedback
                        :state="validateRef('bigQueryForm.table')"
                        invalid="This field is required"
                        valid="Table is valid"
                    ></Feedback>
                </b-col>
            </b-row>
        </b-form-group>

        <b-form-group v-if="bqError.state === false" :label-cols="2" :horizontal="true">
            <b-row>
                <b-col md="8">
                    <Feedback :state="bqError.state" :invalid="bqError.message"></Feedback>
                </b-col>
            </b-row>
        </b-form-group>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'

export default {
    components: {
        Feedback,
    },
    mixins: [validationMixin],
    validations: {
        bigQueryForm: {
            project: {
                valid: function() {
                    return this.isCustomProjectId
                        ? Boolean(this.bigQueryForm.customProjectId)
                        : Boolean(this.bigQueryForm.project)
                },
            },
            dataset: {
                required: requiredIf(function() {
                    return ['dataset', 'table'].includes(this.depth)
                }),
            },
            table: {
                required: requiredIf(function() {
                    return this.depth === 'table'
                }),
            },
            customProjectId: {
                required: requiredIf(function() {
                    return this.isCustomProjectId
                }),
            },
        },
    },
    props: {
        value: {
            required: true,
            type: Object,
        },
        depth: {
            // 'project'/'dataset'/'table'
            type: String,
            default: 'table',
        },
        disable: {
            type: Boolean,
            default: false,
        },
        datasetFilter: {
            type: String,
        },
        tableFilter: {
            type: String,
        },
    },
    data() {
        return {
            bigQueryForm: {},
            bqError: {
                state: null,
                message: null,
            },
            datasetsFetched: false,
            tablesFetched: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            projects: 'bigquery/projects',
            datasets: 'bigquery/datasets',
            tables: 'bigquery/tables',
        }),
        isCustomProjectId() {
            return this.bigQueryForm.project === 'Custom projectId'
        },
        isSandbox() {
            return this.activeProject.type === 'SANDBOX'
        },
    },
    async created() {
        if (this.value) {
            const projectFound = this.projects.find(el => el === this.value.project)

            const tempBigQueryForm = {
                ...this.bigQueryForm,
                ...{
                    project: projectFound ? this.value.project : 'Custom projectId',
                    dataset: this.value.dataset,
                    table: this.value.table,
                    customProjectId: projectFound ? null : this.value.project,
                },
            }

            this.bigQueryForm = tempBigQueryForm
        }
        await this.fetchProjects()
    },
    watch: {
        value() {
            const projectFound = this.projects.find(el => el === this.value.project)

            const tempBigQueryForm = {
                ...this.bigQueryForm,
                ...{
                    project: projectFound ? this.value.project : 'Custom projectId',
                    dataset: this.value.dataset,
                    table: this.value.table,
                    customProjectId: projectFound ? this.value.customProjectId : this.value.project,
                },
            }

            this.bigQueryForm = tempBigQueryForm
        },
    },
    methods: {
        fetchProjects() {
            return new Promise((resolve, reject) => {
                this.bqError = {
                    state: null,
                    message: null,
                }
                this.$store.commit('loading/PROCESSING', `Getting Google Cloud Projects...`)
                this.datasetsFetched = false
                this.tablesFetched = false
                this.bigQueryForm.project = null

                this.$store
                    .dispatch('bigquery/getProjects', this.activeProject.id)
                    .then(projects => {
                        this.$store.commit('loading/PROCESSED')
                        resolve(projects)
                    })
                    .catch(exception => {
                        this.bqError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$store.commit('loading/PROCESSED')
                        reject(exception)
                    })
            })
        },

        fetchDatasets() {
            if (['dataset', 'table'].includes(this.depth) && this.bigQueryForm.project) {
                return new Promise((resolve, reject) => {
                    this.bqError = {
                        state: null,
                        message: null,
                    }

                    this.$store.commit('loading/PROCESSING', `Fetching the datasets...`)
                    this.tablesFetched = false
                    this.bigQueryForm.dataset = null

                    this.$store
                        .dispatch('bigquery/getDatasets', {
                            witcloudProjectId: this.activeProject.id,
                            googleCloudProjectId: this.isCustomProjectId
                                ? this.bigQueryForm.customProjectId
                                : this.bigQueryForm.project,
                        })
                        .then(datasets => {
                            this.datasetsFetched = true
                            this.$store.commit('loading/PROCESSED')
                            resolve(datasets)
                        })
                        .catch(exception => {
                            this.bqError = {
                                state: false,
                                message: exception.response.data.data,
                            }
                            this.$store.commit('loading/PROCESSED')
                            reject(exception)
                        })
                })
            }
        },

        fetchTables() {
            if (this.depth === 'table' && this.bigQueryForm.project && this.bigQueryForm.dataset) {
                return new Promise((resolve, reject) => {
                    this.bqError = {
                        state: null,
                        message: null,
                    }

                    this.$store.commit('loading/PROCESSING', `Fetching the tables...`)
                    this.bigQueryForm.table = null
                    const options = {
                        witcloudProjectId: this.activeProject.id,
                        googleCloudProjectId: this.isCustomProjectId
                            ? this.bigQueryForm.customProjectId
                            : this.bigQueryForm.project,
                        datasetId: this.bigQueryForm.dataset,
                    }

                    this.$store
                        .dispatch('bigquery/getTables', options)
                        .then(tables => {
                            this.tablesFetched = true
                            this.$store.commit('loading/PROCESSED')
                            resolve(tables)
                        })
                        .catch(exception => {
                            this.bqError = {
                                state: false,
                                message: exception.response.data.data,
                            }
                            this.$store.commit('loading/PROCESSED')
                            reject(exception)
                        })
                })
            }
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },

        onChange() {
            this.$emit('input', {
                project: this.isCustomProjectId ? this.bigQueryForm.customProjectId : this.bigQueryForm.project,
                dataset: this.bigQueryForm.dataset,
                table: this.bigQueryForm.table,
            })
        },

        onProjectChange() {
            this.bigQueryForm.dataset = null
            this.bigQueryForm.table = null

            this.datasetsFetched = false
            this.tablesFetched = false
        },

        onDatasetChange() {
            this.bigQueryForm.table = null

            this.tablesFetched = false
        },

        async fetchAll(projectId, datasetId, tableId) {
            await this.fetchProjects()
            this.bigQueryForm.project = this.$store.state.bigquery.projects.find(el => el === projectId) || projectId

            await this.fetchDatasets()
            this.bigQueryForm.dataset = this.$store.state.bigquery.datasets.find(el => el === datasetId)

            await this.fetchTables()
            this.bigQueryForm.table = this.$store.state.bigquery.tables.find(el => el === tableId)

            this.onChange()
        },
    },
}
</script>

<style lang="scss">
.refresh-projects-row {
    .refresh-projects-col {
        .btn {
            margin-top: 1px;
        }
    }
}
</style>
