<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/storage_36.svg"
            headerText="Setup Your Process"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            @finish="createProcess"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.processForm.name.$model"
                                type="text"
                                placeholder="Enter Process name"
                                :id="`${formId}-name`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('processForm.name', step)"
                                invalid="This field is required"
                                valid="Process name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Select Source" :label-cols="2" :horizontal="true">
                    <b-row class="push-down-form-row">
                        <b-col md="8">
                            <b-form-radio-group
                                v-model="source"
                                :options="sourceOptions"
                                :id="`${formId}-source`"
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    v-if="source === 'Query Job'"
                    label="Query Job"
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row>
                        <b-col md="8">
                            <wit-select
                                v-model="$v.queryJobSource.queryJob.$model"
                                label="name"
                                placeholder="Select Query Job"
                                :options="queryJobs"
                                :id="`${formId}-queryjob`"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('queryJobSource.queryJob', step)"
                                invalid="This field is required"
                                valid="Query Job is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <div v-else>
                    <ChooseBQTable
                        v-model="$v.bigQuerySource.$model"
                        :needsAuth="true"
                        depth="table"
                    />
                </div>

                <p
                    :class="{
                        'm-1': true,
                        'collapse-section-button': true,
                        'arrow-right': optionsShown,
                        'arrow-down': !optionsShown,
                    }"
                    @click="optionsShown = !optionsShown"
                >Advanced options</p>
                <b-collapse v-model="optionsShown">
                    <b-form-group label="Output Format" :label-cols="2" :horizontal="true">
                        <b-row class="push-down-form-row">
                            <b-col md="8">
                                <b-form-radio-group
                                    v-model="$v.optionsForm.outputFormat.$model"
                                    :options="outputFormatOptions"
                                ></b-form-radio-group>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group label="Use Compression" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.useCompression.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Make file public" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.public.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Headers" :label-cols="2" :horizontal="true">
                        <b-row>
                            <b-col md="8">
                                <b-form-checkbox-group class="push-down-form-row">
                                    <label
                                        class="switch switch-label switch-pill switch-primary switch-show-password"
                                        data-children-count="1"
                                    >
                                        <input
                                            class="switch-input"
                                            type="checkbox"
                                            v-model="$v.optionsForm.printHeader.$model"
                                        />
                                        <span
                                            class="switch-slider"
                                            data-checked="On"
                                            data-unchecked="Off"
                                        ></span>
                                    </label>
                                </b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-collapse>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'

import ChooseBQTable from '@/components/ChooseBQTable.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'
import {processMixin} from '@/mixins/processMixin'

export default {
    components: {
        ChooseBQTable,
        Feedback,
        Loading,
        WitTabbedForm,
    },
    data() {
        return {
            formId: 'save-to-storage-create-form',
            processForm: {
                name: '',
            },
            source: 'Query Job',
            sourceOptions: ['Query Job', 'BigQuery'],
            bigQuerySource: {
                project: null,
                dataset: null,
                table: null,
            },
            queryJobSource: {
                queryJob: null,
            },
            optionsForm: {
                outputFormat: 'CSV',
                useCompression: false,
                public: false,
                printHeader: true,
            },
            outputFormatOptions: ['CSV', 'AVRO'],
            optionsShown: false,
            processError: {
                state: null,
                message: null,
            },
        }
    },
    mixins: [formMixin, processMixin, validationMixin],
    validations: {
        processForm: {
            name: {required},
        },
        optionsForm: {
            outputFormat: {required},
            useCompression: {required},
            public: {required},
            printHeader: {required},
        },
        queryJobSource: {
            queryJob: {
                required: requiredIf(function() {
                    return this.source === 'Query Job'
                }),
            },
        },
        bigQuerySource: {
            project: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery'
                }),
            },
            dataset: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery'
                }),
            },
            table: {
                required: requiredIf(function() {
                    return this.source === 'BigQuery'
                }),
            },
        },
    },
    computed: {
        ...mapGetters({
            pipelines: 'pipeline/pipelines',
            queryJobs: 'report/queryJobs',
            activeProject: 'project/active',
        }),
        steps() {
            return [
                {
                    name: 'Process Settings',
                    invalid: this.$v.$invalid,
                },
            ]
        },
    },
    async created() {
        this.$store.dispatch('google/init')
        await this.$store.dispatch('report/fetchQueryJobs', {projectId: this.activeProject.id})

        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
        const formSessionId = formSessionMap[this.$route.path].id

        this.$gtm.trackEvent({
            event: 'create_resource_start',
            action: 'create',
            value: formSessionId,
        })
    },
    methods: {
        createProcess(step) {
            step.check()
            this.$forceUpdate()
            if (!this.$v.$invalid) {
                let data = {
                    ...this.processForm,
                    options: this.optionsForm,
                    source: {},
                    projectId: this.activeProject.id,
                }
                if (this.source === 'Query Job') {
                    data.source = {
                        queryJobId: this.queryJobSource.queryJob.id,
                    }
                }
                if (this.source === 'BigQuery') {
                    data.source = {
                        tableSource: {
                            projectId: this.bigQuerySource.project,
                            datasetId: this.bigQuerySource.dataset,
                            tableId: this.bigQuerySource.table,
                        },
                    }
                }

                this.$store.commit('loading/PROCESSING', `Creating Your Process...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/process/saveToStorage`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.processError.state = false
                        this.processError.message = exception.response.data.data
                        this.$errorHandler.report(exception, 'Could not create form')
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.push-down-form-row {
    margin-top: 5px;
}

.make-sure-col {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.7rem;
    opacity: 0.8;
}
</style>
